import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";
import { useColorContext } from "../components/context/color-context";

// Components
import { Gallery } from "../components/images/gallery";
import { SingleVideoEmbed } from "../components/videos/single-video-embed";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .single-image {
    & .single-image-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .single-video {
    & .single-video-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .text {
    & .text-container {
      /* grid-column: 1 / 5; */

      max-width: 895px;

      @media (max-width: 768px) {
        grid-column: 1 / 7;

        & h1,
        & h2 {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    & .sidebar-text-container {
      width: 100%;
      /* max-width: 340px; */
      /* margin: 0 0 0 40px; */

      & .text-content {
        position: sticky;
        top: 120px;

        background-color: ${props => props.highlightColor};
        padding: 20px;
      }

      & p {
        font-size: 16px;
        line-height: 23px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  & .gallery {
    margin: 0 0 70px 0;

    & > div {
      grid-column: 1 / 7;

      max-width: 900px;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      margin: 40px 0;
    }
  }

  & .related-projects {
    & .section-title {
      grid-column: 1 / 7;

      margin: 0 0 40px 0;

      @media (max-width: 768px) {
        margin: 0;

        & h3 {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    & .related-projects-container {
      grid-column: 1 / 7;
      grid-row-gap: 35px;

      & article {
        grid-column: span 2;

        & .title {
          margin: 14px 0 0 0;
        }

        @media (max-width: 768px) {
          grid-column: span 6;

          & h1,
          & h2 {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const MinMaxGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(500px, 895px) 340px;
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 1000px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const Title = styled.div`
  & h1,
  & h2 {
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  & .additional-text {
    & p {
      margin: 0;
    }
  }
`;

const TagsContainer = styled.div`
  margin: 14px 0 0 0;

  & ol {
    margin: 0 0 10px 0;

    & li {
      padding: 5px 10px;
      margin: 0 10px 0 0;

      font-size: 16px;
      line-height: 23px;

      background: #ffffff;
      border-radius: 3px;

      & a {
        text-decoration: none;
      }

      &:hover {
        background-color: #000;
        color: #fff;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
      }
    }
  }

  & .event-link {
    display: block;
    width: fit-content;

    padding: 5px 10px;
    margin: 0;

    font-size: 16px;
    line-height: 23px;

    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0;
    }

    background: #ffffff;
    border-radius: 3px;

    color: #000;
    text-decoration: none;

    &:hover {
      background-color: #000;

      color: #fff;
    }
  }
`;

const Project = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  const { randomColors } = useColorContext();

  useEffect(() => {
    setPageType(`project`);
  }, [data]);

  // Url link back to homepage with search params
  // const currentParams = useLocation().search;
  // const toWithParams = `${to}${currentParams}`;

  const where = data.prismicProject.data.places
    .filter(place => place !== "")
    .filter(place => place.place !== null)
    .map((place, index) => (
      <li key={`single_project_place_${index}_${data.prismicProject.id}`}>
        <Link to={`/?where=${place.place.toLowerCase()}`}>{place.place}</Link>
      </li>
    ));

  const what = data.prismicProject.data.types
    .filter(type => type !== "")
    .filter(type => type.type !== null)
    .map((type, index) => (
      <li key={`single_project_type_${index}_${data.prismicProject.id}`}>
        <Link to={`/?what=${type.type.toLowerCase()}`}>{type.type}</Link>
      </li>
    ));

  const content = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "full_width_text") {
      return (
        <Grid
          key={`full_width_text_${index}_${content.id}`}
          className="full-width-text"
        >
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Grid>
      );
    }

    if (content.slice_type === "text") {
      return (
        <MinMaxGrid key={`text_${index}_${content.id}`} className="text">
          <div className="text-container">
            <div
              dangerouslySetInnerHTML={{
                __html: content.primary.section_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.primary.section_text.html,
              }}
            />
          </div>

          {content.primary.sidebar_text.text.length >= 1 && (
            <div className="sidebar-text-container">
              <div className="text-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.primary.sidebar_text.html,
                  }}
                />
              </div>
            </div>
          )}
        </MinMaxGrid>
      );
    }

    if (content.slice_type === "images") {
      return (
        <Grid key={`images_${index}_${content.id}`} className="gallery">
          <Gallery images={content.items} />
        </Grid>
      );
    }

    if (content.slice_type === "image") {
      return (
        <Grid key={`image_${index}_${content.id}`} className="single-image">
          <div className="single-image-container">
            {content.primary.image1 !== null && (
              <>
                {content.primary.image1.fluid !== null && (
                  <img
                    // className={ImageOrientation(content.image)}
                    srcSet={content.primary.image1.fluid.srcSetWebp}
                    src={content.primary.image1.fluid.srcWebp}
                    alt={content.primary.image1.alt}
                    loading={`lazy`}
                  />
                )}
              </>
            )}
          </div>
        </Grid>
      );
    }

    if (content.slice_type === "video") {
      return (
        <Grid key={`video_${index}_${content.id}`} className="single-video">
          <div className="single-video-container">
            <SingleVideoEmbed content={content} index={index} />
          </div>
        </Grid>
      );
    }

    if (content.slice_type === "related_projects") {
      const relatedProjects = content.items.map((project, index) => {
        if (project.related_project.document === null) return;

        const relatedProjectsWhere = project.related_project.document.data.places
          .filter(place => place !== "")
          .map((place, index) => (
            <li
              key={`single_related_project_place_${index}_${data.prismicProject.id}_${project.related_project.document.id}`}
            >
              <Link to={`/?where=${place.place}`}>{place.place}</Link>
            </li>
          ));

        const relatedProjectsWhat = project.related_project.document.data.types
          .filter(type => type !== "")
          .map((type, index) => (
            <li
              key={`single_related_project_type_${index}_${data.prismicProject.id}_${project.related_project.document.id}`}
            >
              <Link to={`/?what=${type.type}`}>{type.type}</Link>
            </li>
          ));

        return (
          <article
            key={`related_project_${project.related_project.document.id}`}
          >
            <div>
              <Link to={project.related_project.document.url}>
                {project.related_project.document.data.image.fluid !== null && (
                  <img
                    srcSet={
                      project.related_project.document.data.image.fluid
                        .srcSetWebp
                    }
                    src={
                      project.related_project.document.data.image.fluid.srcWebp
                    }
                    alt={project.related_project.document.data.image.alt}
                    loading={`lazy`}
                  />
                )}
              </Link>
              <div
                className="title uppercase"
                dangerouslySetInnerHTML={{
                  __html: project.related_project.document.data.title.html,
                }}
              />
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: project.related_project.document.data.subtitle.html,
                }}
              />
            </div>

            <TagsContainer className="flex row flex-wrap">
              <ol className="flex row flex-wrap">{relatedProjectsWhere}</ol>
              <ol className="flex row flex-wrap">{relatedProjectsWhat}</ol>
            </TagsContainer>
          </article>
        );
      });

      return (
        <Grid key={`text_${index}_${content.id}`} className="related-projects">
          <div className="section-title">
            <h3 className="uppercase">Related Projects</h3>
          </div>

          <Grid className="related-projects-container">{relatedProjects}</Grid>
        </Grid>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicProject.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicProject.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicProject.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicProject.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:image",
            content: `${
              data.prismicProject.data.image.url !== ""
                ? data.prismicProject.data.image.url
                : ""
            }`,
          },
          {
            property: "og:image:secure_url",
            content: `${
              data.prismicProject.data.image.url !== ""
                ? data.prismicProject.data.image.url
                : ""
            }`,
          },
          {
            property: "twitter:image",
            content: `${
              data.prismicProject.data.image.url !== ""
                ? data.prismicProject.data.image.url
                : ""
            }`,
          },
        ]}
      />
      <Page>
        <Title>
          <div
            className="title uppercase"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.title.html,
            }}
          />
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.subtitle.html,
            }}
          />
          <div
            className="additional-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.additional_text.html,
            }}
          />

          <TagsContainer className="flex row flex-wrap">
            {data.prismicProject.data.event_link.url !== "" ? (
              <a
                className="event-link"
                href={data.prismicProject.data.event_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.prismicProject.data.event_link_text}
              </a>
            ) : (
              <>
                <ol className="flex row flex-wrap">{where}</ol>
                <ol className="flex row flex-wrap">{what}</ol>
              </>
            )}
          </TagsContainer>
        </Title>

        <ContentContainer highlightColor={randomColors[1]}>
          {content}
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(Project);

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      id
      data {
        title {
          html
          text
        }
        subtitle {
          html
        }
        additional_text {
          html
        }
        places {
          place
        }
        types {
          type
        }
        image {
          alt
          url
        }
        event_link {
          url
        }
        event_link_text
        body {
          ... on PrismicProjectBodyFullWidthText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImages {
            id
            slice_type
            items {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicProjectBodyText {
            id
            slice_type
            primary {
              section_title {
                html
              }
              section_text {
                html
              }
              sidebar_text {
                html
                text
              }
            }
          }
          ... on PrismicProjectBodyImage {
            id
            slice_type
            primary {
              image1 {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
              }
            }
          }
          ... on PrismicProjectBodyVideo {
            id
            slice_type
            primary {
              video {
                height
                html
                width
              }
            }
          }
          ... on PrismicProjectBodyRelatedProjects {
            id
            slice_type
            items {
              related_project {
                document {
                  ... on PrismicProject {
                    id
                    url
                    data {
                      image {
                        fluid {
                          srcWebp
                          srcSetWebp
                        }
                        alt
                      }
                      title {
                        html
                      }
                      subtitle {
                        html
                      }
                      places {
                        place
                      }
                      types {
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
